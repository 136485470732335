import * as React from "react"
import Layout from "../components/Layout";
import {graphql, Link} from 'gatsby';
import SEO from "../components/SEO";

export const query = graphql`
    {
    allSanitySettings{
        edges {
      node {
        addresszeile1
        addresszeile2
        addresszeile3
        kontaktemail
        telefon
        instalink
        fblink
      }
    }
    }
    allSanityStartseite {
    edges {
      node {
      livevid
      albumcovers {
          _key
        }
      }
      }
      }
  }
`

const ErrorPage = ({data}) => {

  const page = data.allSanityStartseite.edges[0].node;

  return (
      <Layout impressum={true} settings={data.allSanitySettings.edges[0].node} live={page.livevid ? true : false} repertoire={page.albumcovers ? true : false}>
          <SEO title={"Error | Fleur de Nuit"}/>
          <div className="fullpage">
          <div className="fullpage__inner centered">
            <h1>404</h1>
            <p>Seite konnte nicht gefunden werden.</p>
              <Link to={"/"} className={"btn bg-dark color-light clash hover-moveup"}>Startseite</Link>
          </div>
        </div>
      </Layout>
  )
}

export default ErrorPage
